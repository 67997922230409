import { AuthorizationEntityNameType, PermissionsModel } from "types";

type PermissionType = {
  permission: PermissionsModel;
  name: AuthorizationEntityNameType;
};

// TOP LEVEL PERMISSIONS

export const ADMIN_PERMISSION: PermissionsModel = "ADMIN";
export const OPEN_PERMISSION: PermissionsModel = "ALL";

// JOBS
export const JOBS_PERMISSION: PermissionType = { permission: OPEN_PERMISSION, name: "Jobs" };

// USERS
export const USERS_PERMISSION: PermissionType = { permission: "USERS", name: "Users" };

// STATS
export const STATS_PERMISSION: PermissionType = { permission: ADMIN_PERMISSION, name: "Stats" };

// SUPPRESSION
export const SUPPRESSION_PERMISSION: PermissionType = {
  permission: "SUPPRESSION",
  name: "Suppression",
};
export const SUPPRESSION_FILE_PERMISSION: PermissionType = {
  permission: "SUPPRESSION_FILE",
  name: "Suppression File",
};
export const SUPPRESSION_LIST_PERMISSION: PermissionType = {
  permission: "SUPPRESSION_LIST",
  name: "Suppression List",
};

// DATABASES
export const DATABASES_PERMISSION: PermissionType = {
  permission: "PIPELINE_DB_ALL",
  name: "Databases",
};
export const DATABASES_SUPPRESSION: PermissionType = {
  permission: "suppression",
  name: "Database suppression",
};
export const DATABASES_GAVIN: PermissionType = {
  permission: "gavin",
  name: "Database gavin",
};
export const DATABASES_BILLIONS: PermissionType = {
  permission: "billions",
  name: "Database billions",
};
export const DATABASES_ITLM: PermissionType = { permission: "itlm", name: "Database itlm" };
export const DATABASES_ITLM_V2: PermissionType = {
  permission: "itlm_v2",
  name: "Database itlm_v2",
};
export const DATABASES_LG: PermissionType = { permission: "lg", name: "Database lg" };

// ALL DATABASES
export const DATABASES_PERMISSIONS = [
  DATABASES_SUPPRESSION,
  DATABASES_BILLIONS,
  DATABASES_ITLM,
  DATABASES_ITLM_V2,
  DATABASES_LG,
  DATABASES_GAVIN,
];

// RECORDS
export const RECORDS_PERMISSION: PermissionType = { permission: "RECORDS", name: "Records" };
export const RECORDS_EMAILS_PERMISSION: PermissionType = {
  permission: "RECORDS_EMAILS",
  name: "Records Email",
};
export const RECORDS_DB_NAME_PERMISSION: PermissionType = {
  permission: "RECORDS_DB_NAME",
  name: "Records DB Name",
};
export const RECORDS_DB_NAMES_PERMISSION: PermissionType = {
  permission: "RECORDS_DB_NAMES",
  name: "Records DB Names",
};

// ALL RECORDS
export const RECORDS_PERMISSIONS = [
  RECORDS_EMAILS_PERMISSION,
  RECORDS_DB_NAME_PERMISSION,
  RECORDS_DB_NAMES_PERMISSION,
];

// PIPELINES
export const PIPELINES_PERMISSION: PermissionType = {
  permission: "PIPELINE",
  name: "Pipelines",
};
export const PIPELINES_LIST_PERMISSION: PermissionType = {
  permission: "PIPELINE_LIST",
  name: "Pipelines",
};
export const PIPELINE_CONVERT_PERMISSION: PermissionType = {
  permission: "PIPELINE_CONVERT",
  name: "Convert",
};
export const PIPELINE_CUT_PERMISSION: PermissionType = {
  permission: "PIPELINE_CSV_CUT",
  name: "Cut",
};
export const PIPELINE_DEDUPLICATE_PERMISSION: PermissionType = {
  permission: "PIPELINE_DEDUPLICATE",
  name: "Deduplicate",
};
export const PIPELINE_DIFF_AND_COMMON_PERMISSION: PermissionType = {
  permission: "PIPELINE_SUBTRACT",
  name: "Diff and Common",
};
export const PIPELINE_DOMAIN_PURIFIER_PERMISSION: PermissionType = {
  permission: "PIPELINE_DOMAIN_PURIFIER",
  name: "Domain Purify",
};
export const PIPELINE_EXTRACT_EMAIL_PERMISSION: PermissionType = {
  permission: "PIPELINE_EXTRACT_EMAIL",
  name: "Extract",
};
export const PIPELINE_ISP_SPLITTER_PERMISSION: PermissionType = {
  permission: "PIPELINE_ISP_SPLITTER",
  name: "ISP Domain Splitter",
};
export const PIPELINE_MATCH_EMAILS_PERMISSION: PermissionType = {
  permission: "PIPELINE_MATCH_EMAILS",
  name: "Match",
};
export const PIPELINE_MERGE_PERMISSION: PermissionType = {
  permission: "PIPELINE_MERGE",
  name: "Merge",
};
export const PIPELINE_MD5_PERMISSION: PermissionType = { permission: "PIPELINE_MD5", name: "MD5" };
export const PIPELINE_SHA256_PERMISSION: PermissionType = {
  permission: "PIPELINE_SHA256",
  name: "SHA256",
};
export const PIPELINE_REVERSE_PERMISSION: PermissionType = {
  permission: "PIPELINE_REVERSE",
  name: "Reverse",
};
export const PIPELINE_SPLIT_FILE_PERMISSION: PermissionType = {
  permission: "PIPELINE_SPLIT_FILE",
  name: "Split",
};
export const PIPELINE_SUM_CLICKS_PERMISSION: PermissionType = {
  permission: "PIPELINE_SUM_CLICKS",
  name: "Sum Clicks",
};
export const PIPELINE_SUPPRESS_PERMISSION: PermissionType = {
  permission: "PIPELINE_SUPPRESS",
  name: "Suppress",
};
export const PIPELINE_MATCH_AND_ELIMINATE_PERMISSION: PermissionType = {
  permission: "PIPELINE_MATCH_AND_ELIMINATE",
  name: "Match and Eliminate",
};
export const PIPELINE_WAM_UP_PERMISSION: PermissionType = {
  permission: "PIPELINE_WARM_UP",
  name: "Warm Up Splitter",
};

// ALL PIPELINES
export const PIPELINES_PERMISSIONS = [
  PIPELINE_CONVERT_PERMISSION,
  PIPELINE_CUT_PERMISSION,
  PIPELINE_DEDUPLICATE_PERMISSION,
  PIPELINE_DIFF_AND_COMMON_PERMISSION,
  PIPELINE_DOMAIN_PURIFIER_PERMISSION,
  PIPELINE_EXTRACT_EMAIL_PERMISSION,
  PIPELINE_ISP_SPLITTER_PERMISSION,
  PIPELINE_MATCH_EMAILS_PERMISSION,
  PIPELINE_MERGE_PERMISSION,
  PIPELINE_MD5_PERMISSION,
  PIPELINE_SHA256_PERMISSION,
  PIPELINE_REVERSE_PERMISSION,
  PIPELINE_SPLIT_FILE_PERMISSION,
  PIPELINE_SUM_CLICKS_PERMISSION,
  PIPELINE_SUPPRESS_PERMISSION,
  PIPELINE_WAM_UP_PERMISSION,
  PIPELINE_MATCH_AND_ELIMINATE_PERMISSION,
];
